

#root {
  width: 100%;
  height: 100%;
  /*transform-origin: left top;*/
  position: relative;

  background-image: url('./img/BackCover.jpg');
  background-size: cover;
  background-position: center;

}

#vk {
  border-radius: 8px;
  padding: 15px;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 7px 0px black;
}

#global {
  position: relative;
  width: 100%;
  height: 100%;
  /*overflow: auto;*/
}

html,
body {
  overflow: hidden !important;
  position: relative;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  background-color: #222222 !important;
  height: 100%;
}

body * {
  user-drag: none;
  user-select: none;
}

h1,
h2,
h3 {
  margin: 0;
}

.vkuiView__panel {
  position: relative !important;
  pointer-events: all !important;
}

@font-face {
  font-family: "Inkverse-BWDRx";
  src:
    local("Inkverse-BWDRx"),
    url("./fonts/Inkverse-BWDRx.ttf") format("truetype");
}

@font-face {
  font-family: "Bellota-Regular";
  font-weight: 400;
  src:
    local("Bellota-Regular"),
    url("./fonts/Bellota-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bellota-Regular";
  font-weight: 600;
  src:
    local("Bellota-Bold"),
    url("./fonts/Bellota-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Bellota-Regular";
  font-weight: 300;
  src:
    local("Bellota-Light"),
    url("./fonts/Bellota-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Spectrashell";
  font-weight: 300;
  src:
    local("Spectrashell"),
    url("./fonts/Spectrashell.ttf") format("truetype");
}
